import PageContents from '@/types/PageContents';
import IntEnts from './intENTS.html';
import IntEntsVideo from './intENTS-Video.html';
import MultizyteFacetCervical from './Multizyte-facet-cervical.html';
import MultizyteFacet from './Multizyte-facet.html';
import MultizyteSacroiliac from './Multizyte-sacroiliac.html';
import MultizyteVideo from './Multizyte-Video.html';
import Multizyte from './Multizyte.html';
import IntroText from './pain-therapy-Intro-Text.html';
import IntroVideo from './pain-therapy-Intro-Video.html';
import AutoplayVideo from './pain-therapy-autoplay-Video.html';

// const distanceX = 7.5;

const videoModalSize = {
  height: 'calc(315px + 4rem)',
  width: 'calc(560px + 4rem)',
};

const pageContent: PageContents[] = [
  {
    button: {
      top: '-30%',
      left: '-30%',
      type: 'video',
      content: '',
    },
    modal: {
      left: 20,
      top: 20,
      height: '60%',
      width: '60%',
      content: AutoplayVideo,
    },
  },
  {
    button: {
      top: '37%',
      left: '12%',
      type: 'video',
      content: 'Intro',
      textAlign: 'left',
    },
    modal: {
      ...videoModalSize,
      content: IntroVideo,
    },
  },
  {
    button: {
      top: '37%',
      left: '16.5%',
      type: 'open',
    },
    modal: {
      content: IntroText,
    },
  },

  {
    button: {
      top: '20%',
      left: '40%',
      type: 'video',
      content: '',
    },
    modal: {
      ...videoModalSize,
      content: MultizyteVideo,
    },
  },
  {
    button: {
      top: '20%',
      left: '44.5%',
      type: 'open',
      content: '',
    },
    modal: {
      content: Multizyte,
    },
  },

  {
    button: {
      top: '33.5%',
      left: '50%',
      type: 'open',
      content: '',
    },
    modal: {
      content: MultizyteFacetCervical,
    },
  },
  {
    button: {
      top: '41.5%',
      left: '46%',
      type: 'open',
      content: '',
    },
    modal: {
      content: MultizyteFacet,
    },
  },
  {
    button: {
      top: '50%',
      left: '48%',
      type: 'open',
      content: '',
    },
    modal: {
      content: MultizyteSacroiliac,
    },
  },

  {
    button: {
      top: '19%',
      left: '67.5%',
      type: 'video',
      content: '',
    },
    modal: {
      ...videoModalSize,
      content: IntEntsVideo,
    },
  },
  {
    button: {
      top: '19%',
      left: '72%',
      type: 'open',
      content: '',
    },
    modal: {
      content: IntEnts,
    },
  },
];

export default pageContent;

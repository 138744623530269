


































import { Component, Vue } from 'vue-property-decorator';
import CircleButton from '@/components/CircleButton.vue';
import Modal from '@/components/Modal.vue';
import LongButton from '@/components/LongButton.vue';

import pageContent from '@/contents/pain-therapy';

@Component({
  components: {
    CircleButton,
    Modal,
    LongButton,
  },
})
export default class Education extends Vue {
  public modalOpen = '';

  public pageContent = pageContent;

  mounted() {
    if (this.$route.redirectedFrom && this.$route.redirectedFrom === '/rooms/products') {
      this.modalOpen = 'modal-0';
    }
  }

  close(id: string) {
    this.modalOpen = this.modalOpen === id ? '' : id;
  }
}

